<template>
  <div
    class="doc-item"
    :class="{solo}"
    @click="download(link)"
  >
    <div class="doc-item__title">
      <div class="icon">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.00028 5.42941V18.5723C4.00028 20.7814 5.79114 22.5723 8.00028 22.5723H16.8574C19.0666 22.5723 20.8574 20.7814 20.8574 18.5723V8.50602C20.8574 7.48978 20.4706 6.51164 19.7756 5.77025L16.8912 2.69364C16.1351 1.88704 15.0787 1.42941 13.9731 1.42941H8.00028C5.79114 1.42941 4.00028 3.22027 4.00028 5.42941ZM6.00028 5.42941C6.00028 4.32484 6.89571 3.42941 8.00028 3.42941H13.9731C14.5259 3.42941 15.0541 3.65822 15.4322 4.06153L18.3165 7.13813C18.664 7.50883 18.8574 7.9979 18.8574 8.50602V18.5723C18.8574 19.6768 17.962 20.5723 16.8574 20.5723H8.00028C6.89571 20.5723 6.00028 19.6768 6.00028 18.5723V5.42941Z" fill="#A9A9A9"/><ellipse cx="9.8577" cy="15.8577" rx="2.14286" ry="2.14286" fill="#A9A9A9"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8 10C8 9.44772 8.35794 9 8.79949 9L16.2005 9C16.6421 9 17 9.44772 17 10C17 10.5523 16.6421 11 16.2005 11L8.79949 11C8.35794 11 8 10.5523 8 10Z" fill="#A9A9A9"/></svg>
      </div>
      <span>{{ label }}</span>
    </div>
    <div class="icon">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18 18.75C18.6904 18.75 19.25 19.3096 19.25 20C19.25 20.6904 18.6904 21.25 18 21.25L6.00001 21.25C5.30965 21.25 4.75001 20.6904 4.75001 20C4.75001 19.3096 5.30965 18.75 6.00001 18.75L18 18.75ZM10.75 4C10.75 3.30964 11.3096 2.75 12 2.75C12.6903 2.75 13.25 3.30964 13.25 4L13.25 13.1933L16.1632 10.5714L17 11.5C16.1638 10.5709 16.1633 10.5714 16.1632 10.5714C16.6764 10.1096 17.4673 10.1506 17.9291 10.6638C18.3909 11.1769 18.3493 11.9673 17.8362 12.4291L12.8362 16.9291C12.3608 17.3569 11.6392 17.3569 11.1638 16.9291L6.16435 12.4296L7 11.5C6.16379 12.4291 6.16432 12.4296 6.16435 12.4296C5.65121 11.9678 5.60905 11.1769 6.07088 10.6638C6.5327 10.1507 7.32306 10.1091 7.8362 10.5709L10.75 13.1933L10.75 4Z" fill="#A9A9A9"/></svg>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  link: {
    type: String,
    required: true,
  },
  solo: {
    type: Boolean,
    default: false
  },
  isolate: {
    type: Boolean,
    default: false
  }
})

const download = file => window.open(props.isolate ? file : `${process.env.VUE_APP_DOCS_REPOSITORY}/docs/${file}`, '_target');

</script>

<style scoped lang="scss">
.doc-item{
  width: 100%;
  background: white;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  transition: ease .2s;
  .icon{
    display: flex;
    svg{
      transition: ease .3s;
    }
  }
  &__title{
    display: flex;
    align-items: center;
    .icon{
      display: flex;
      svg{
        transition: ease .3s;
      }
    }
    span{
      font-size: 16px;
      line-height: 16px;
      padding: 0 20px;
      transition: ease .3s
    }
  }
  &.solo{
    margin-bottom: 16px;
  }
  &:hover{
    .icon{
      svg * {
        fill: $ec-green;
      }
    }
    .doc-item__title{
      span{
        color: $ec-green;
      }
      .icon{
        svg * {
          fill: $ec-green;
        }
      }
    }
  }
}
</style>
