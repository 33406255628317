<template>
  <div class="article" v-if="article">
    <div class="page-title">
      <img @click="router.push('/news')" src="@/assets/images/back.svg">
      {{article.title}}
    </div>
    <div class="article__row">
      <div class="article__author">{{article.author}}</div>
      <div class="article__date">{{moment.unix(article.date).format('DD.MM.YYYY ・ HH:mm')}} {{article.isEdited}}</div>
    </div>
    <img class="article__poster" :src="article.preview">
    <div class="article__content-strapi content-strapi" v-html="article.content" />
    <div class="article__document">
      <ECDocItem
        v-for="doc in article.documents"
        :key="doc.url"
        :label="doc.title"
        :link="`${uploadsEnv}${doc.url}`"
        solo
        isolate
      />
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted } from 'vue'
  import { useRoute } from 'vue-router'
  import { useRouter } from 'vue-router'
  import ECDocItem from '@/views/docs/ECDockItem'
  import moment from 'moment'
  import axios from 'axios'

  const route = useRoute()
  const router = useRouter()
  const uploadsEnv = process.env.VUE_APP_STRAPI_UPLOADS

  onMounted(async()=> await getArticle())

  const article = ref(null)

  const dates = ref(null)

  const getArticle = async () => {
    const id = route.params.article
    try {
      const req = await axios.get(`${process.env.VUE_APP_STRAPI_API}/news/${id}?populate=preview,documents`)

      article.value = {
        id: req.data.data.id,
        title: req.data.data.attributes.title ? req.data.data.attributes.title : '',
        author: req.data.data.attributes.author ? req.data.data.attributes.author : '',
        content: req.data.data.attributes.content ? req.data.data.attributes.content : '',
        preview: req.data.data.attributes.preview.data ? `${uploadsEnv}${req.data.data.attributes.preview.data[0].attributes.url}` : require('@/assets/images/no-article-preview.svg'),
        documents: req.data.data.attributes.documents.data ? req.data.data.attributes.documents.data.map(item => {
          return {
            title: item.attributes.name,
            url: item.attributes.url
          }
        }) : [],
        date: req.data.data.attributes.publishedAt,
        date: req.data.data.attributes.userdate ? parseInt((new Date(req.data.data.attributes.userdate).getTime() / 1000).toFixed(0)) : parseInt((new Date(req.data.data.attributes.publishedAt).getTime() / 1000).toFixed(0)),
        isEdited: (+moment(req.data.data.attributes.publishedAt).format('x') + 60000) < +moment(req.data.data.attributes.updatedAt).format('x') ? '(Отредактировано)' : ''
      }
    } catch (error) {
      router.push('/not-found')
    }
  }
</script>

<style scoped lang="scss">
.page-title{
  display: flex;
  align-items: flex-start;
  margin-bottom: 6px;
  padding-left: 0;
  img{
    margin-right: 10px;
    margin-top: 3px;
    cursor: pointer;
  }
}
.article{
  &__row{
    display: flex;
    padding-left: 35px;
    margin-bottom: 30px;
  }
  &__author,
  &__date{
    margin-right: 20px;
    opacity: .6;
    font-size: 12px;
  }
  &__poster{
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 30px;
  }
}
</style>

